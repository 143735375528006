import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  resizeCanvas,
  initializeGame,
  drawPlayer,
  drawBullets,
  drawInvaders,
  movePlayer,
  moveInvaders,
  shoot,
  detectCollisions,
  detectPlayerInvaderCollision,
  handleTouchStart,
  handleTouchMove,
  handleTouchEnd,
  handleTap,
} from './gameLogic';

const StyledCanvas = styled.canvas``;
const GameContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Make sure the container takes full width */
  height: 100%; /* Make sure the container takes full height */
`;

const DEFAULT_CANVAS_WIDTH = 800;
const DEFAULT_CANVAS_HEIGHT = 600;
const DEFAULT_INVADER_SPEED = 1;

const isMobileDevice =
  typeof navigator !== 'undefined' &&
  navigator.userAgent.toLowerCase().includes('mobile');

const Game = () => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const playerRef = useRef({
    width: 50,
    height: 30,
    x: 375,
    y: 560,
    speed: 5,
    dx: 0,
  });
  const bulletsRef = useRef([]);
  const invadersRef = useRef([]);
  const invaderSpeedRef = useRef(DEFAULT_INVADER_SPEED);
  const requestRef = useRef(null); // To store requestAnimationFrame reference
  const [canvasSize, setCanvasSize] = useState({
    width: DEFAULT_CANVAS_WIDTH,
    height: DEFAULT_CANVAS_HEIGHT,
  });
  const [cellSize, setCellSize] = useState(10);

  useEffect(() => {
    resizeCanvas(containerRef, setCellSize, setCanvasSize); // Initialize canvas size based on parent container

    window.addEventListener('resize', () =>
      resizeCanvas(containerRef, setCellSize, setCanvasSize),
    ); // Update size on window resize

    return () => {
      window.removeEventListener('resize', () =>
        resizeCanvas(containerRef, setCellSize, setCanvasSize),
      );
    };
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const canvas2dContext = canvas.getContext('2d');

    const update = () => {
      canvas2dContext.clearRect(0, 0, canvas.width, canvas.height);

      movePlayer(playerRef, canvas);
      moveInvaders(invadersRef, invaderSpeedRef, canvas);
      detectCollisions(bulletsRef, invadersRef);
      if (
        detectPlayerInvaderCollision(playerRef, invadersRef) ||
        invadersRef.current.length === 0
      ) {
        cancelAnimationFrame(requestRef.current);
        initializeGame(
          cellSize,
          playerRef,
          canvasSize,
          invadersRef,
          bulletsRef,
        );
        requestRef.current = requestAnimationFrame(update); // Restart the game loop
      } else {
        drawPlayer(playerRef, canvas2dContext);
        drawBullets(bulletsRef, canvas2dContext);
        drawInvaders(invadersRef, cellSize, canvas2dContext);

        requestRef.current = requestAnimationFrame(update);
      }
    };

    const keyDown = (e) => {
      const player = playerRef.current;
      if (e.key === 'ArrowRight' || e.key === 'Right') {
        player.dx = player.speed;
      } else if (e.key === 'ArrowLeft' || e.key === 'Left') {
        player.dx = -player.speed;
      } else if (e.key === ' ' || e.key === 'Spacebar') {
        shoot(playerRef, bulletsRef, cellSize);
      }
    };

    const keyUp = (e) => {
      const player = playerRef.current;
      if (
        e.key === 'ArrowRight' ||
        e.key === 'Right' ||
        e.key === 'ArrowLeft' ||
        e.key === 'Left'
      ) {
        player.dx = 0;
      }
    };

    if (isMobileDevice) {
      canvas.addEventListener('touchstart', (e) =>
        handleTouchStart(e, playerRef),
      );
      canvas.addEventListener('touchmove', (e) =>
        handleTouchMove(e, playerRef),
      );
      canvas.addEventListener('touchend', () =>
        handleTouchEnd(playerRef),
      );
      canvas.addEventListener('touchend', () =>
        handleTap(playerRef, bulletsRef, cellSize),
      );
    } else {
      document.addEventListener('keydown', keyDown);
      document.addEventListener('keyup', keyUp);
    }
    initializeGame(
      cellSize,
      playerRef,
      canvasSize,
      invadersRef,
      bulletsRef,
    );
    requestRef.current = requestAnimationFrame(update);

    return () => {
      if (isMobileDevice) {
        canvas.removeEventListener('touchstart', handleTouchStart);
        canvas.removeEventListener('touchmove', handleTouchMove);
        canvas.removeEventListener('touchend', handleTouchEnd);
        canvas.removeEventListener('touchend', handleTap);
      }
      document.removeEventListener('keydown', keyDown);
      document.removeEventListener('keyup', keyUp);
      cancelAnimationFrame(requestRef.current);
    };
  }, [canvasSize, cellSize, isMobileDevice]);

  return (
    <GameContainer ref={containerRef}>
      <StyledCanvas
        ref={canvasRef}
        width={canvasSize.width}
        height={canvasSize.height}
      >
        Game
      </StyledCanvas>{' '}
    </GameContainer>
  );
};

export default Game;
