import React from 'react';
import { Box } from '@material-ui/core';

import Game from '../components/spaceInvaders/Game';

const Landing = () => {
  return (
    <Box bgcolor={'var(--color-primary-dark)'} height="90%">
      <Game />
    </Box>
  );
};

export default Landing;
