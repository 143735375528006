import React from 'react';

import Landing from '../sections/landing';
import Header from '../sections/header';
import { Box } from '@material-ui/core';

const IndexPage = () => (
  <Box height="100vh">
    <Header />
    <Landing />
  </Box>
);

export default IndexPage;
